export default class Usuario {
    constructor() {
        this.id_user = '';
        this.ds_login = '';
        this.st_ativo = null;
        this.is_lite_readonly = null;
        this.tp_usuario = null;
        this.password = '';
        this.name = '';
    }
}
