<template>
    <v-card>
        <v-container>
            <v-form ref="form" v-model="valid">
                <v-row>
                    <v-col
                        cols="12"
                        sm="12"
                        :md="superUsuario ? '8' : '12'"
                        class="px-4"
                    >
                        <v-card-title
                            v-if="isExtraSmall || isSmall"
                            class="
                                pa-3
                                text-center
                                grey--text
                                font-weight-black
                            "
                            style="display: flex; justify-content: center;"
                        >
                            Editar Usuário
                        </v-card-title>
                        <v-row :class="!isExtraSmall && !isSmall ? 'mt-4' : ''">
                            <v-col
                                cols="12"
                                sm="12"
                                md="12"
                                :class="isExtraSmall ? 'pb-0' : 'py-0'"
                            >
                                <v-card-text
                                    :class="!isExtraSmall ? 'mx-3' : ''"
                                >
                                    Nome do Usuário
                                </v-card-text>
                                <v-text-field
                                    solo
                                    :prepend-icon="
                                        !isExtraSmall ? 'mdi-text-account' : ''
                                    "
                                    name="Nome"
                                    label="Nome"
                                    type="text"
                                    counter
                                    maxlength="45"
                                    hint="Tamanho máximo 45 (não permite números e acentos)"
                                    v-model="usuario.name"
                                    :rules="nameRulesConfirmation"
                                    required
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="">
                            <v-col
                                cols="12"
                                sm="12"
                                md="12"
                                lg="12"
                                :class="isExtraSmall ? 'py-0' : 'py-0'"
                            >
                                <v-card-text
                                    :class="!isExtraSmall ? 'mx-3' : ''"
                                >
                                    Login do Usuário
                                </v-card-text>
                                <v-text-field
                                    v-if="isSuperAdminRecuperi"
                                    solo
                                    :prepend-icon="
                                        !isExtraSmall
                                            ? 'mdi-account-circle'
                                            : ''
                                    "
                                    name="login"
                                    label="Login"
                                    type="text"
                                    data-cy="usuarioLogin"
                                    v-model="usuario.ds_login"
                                    :rules="loginRulesAdminConfirmation"
                                    @blur="
                                        verificarPreenchimentoLoginAdmin()
                                    "
                                    required
                                ></v-text-field>
                                <v-text-field
                                    v-else
                                    solo
                                    :prepend-icon="
                                        !isExtraSmall
                                            ? 'mdi-account-circle'
                                            : ''
                                    "
                                    name="login"
                                    label="Login"
                                    type="text"
                                    data-cy="usuarioLogin"
                                    v-model="usuario.ds_login"
                                    :rules="loginRulesConfirmation"
                                    readonly
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="12"
                                md="12"
                                lg="12"
                                :class="isExtraSmall ? 'py-0' : 'py-0'"
                            >
                                <v-card-text
                                    :class="!isExtraSmall ? 'mx-3' : ''"
                                >
                                    E-mail do Usuário
                                </v-card-text>
                                <v-text-field
                                    solo
                                    :prepend-icon="!isExtraSmall ? 'email' : ''"
                                    name="Email"
                                    label="Email"
                                    type="text"
                                    data-cy="signinPasswordField"
                                    v-model="usuario.ds_email"
                                    :rules="emailRulesConfirmation"
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="12"
                                md="12"
                                lg="6"
                                :class="isExtraSmall ? 'py-0' : 'py-0'"
                            >
                                <v-card-text
                                    :class="!isExtraSmall ? 'mx-3' : ''"
                                >
                                    Tipo do Usuário
                                </v-card-text>
                                <v-select
                                    solo
                                    color="success"
                                    :items="tpUsuario"
                                    v-model="usuario.tp_usuario"
                                    label="Tipo de Usuário"
                                    required
                                    :prepend-icon="
                                        !isExtraSmall ? 'mdi-chevron-right' : ''
                                    "
                                ></v-select>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="12"
                                md="12"
                                lg="6"
                                :class="isExtraSmall ? 'py-0' : 'py-0'"
                            >
                                <v-card-text
                                    :class="!isExtraSmall ? 'mx-3' : ''"
                                >
                                    Agrupador
                                </v-card-text>
                                <v-select
                                    solo
                                    :disabled="
                                        loadingAgrupadores ||
                                            usuarioAgrupador != null
                                    "
                                    :loading="loadingAgrupadores"
                                    :items="agrupadores"
                                    v-model="usuario.ds_agrupador"
                                    label="Agrupador"
                                    :prepend-icon="
                                        !isExtraSmall ? 'mdi-chevron-right' : ''
                                    "
                                    item-text="nm_agrupador"
                                    item-value="ds_agrupador"
                                    data-cy="usuarioAgrupador"
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                sm="4"
                                md="4"
                                :class="isExtraSmall ? 'pb-6' : ''"
                            >
                                <v-row class="d-flex justify-center">
                                    <v-switch
                                        outlined
                                        class="ml-3"
                                        color="success"
                                        v-model="usuario.st_ativo"
                                        label="Está ativo?"
                                        required
                                        data-cy="btnStatusUsuario"
                                    ></v-switch>
                                </v-row>
                            </v-col>
                            <v-col cols="12" sm="4" md="4">
                                <v-tooltip top>
                                    <template
                                        v-slot:activator="{
                                            on,
                                            attrs
                                        }"
                                    >
                                        <v-row
                                            class="
                                                d-flex
                                                justify-center
                                            "
                                        >
                                            <v-switch
                                                outlined
                                                color="success"
                                                v-model="isUserApi"
                                                label="Usuario de API"
                                                required
                                                data-cy="usuarioAPI"
                                            ></v-switch>
                                            <v-btn
                                                v-if="!isExtraSmall"
                                                elevation="0"
                                                class="ma-2"
                                                small
                                                fab
                                            >
                                                <v-icon
                                                    color="grey lighten-2"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                    help
                                                </v-icon>
                                            </v-btn>
                                        </v-row>
                                    </template>
                                    <span>
                                        Usuários de API são usados
                                        exclusivamente para integrações.
                                        Selecione essa opção para definir se o
                                        usuário é de API.
                                    </span>
                                </v-tooltip>
                            </v-col>
                            <v-col cols="12" sm="4" md="4">
                                <v-tooltip top>
                                    <template
                                        v-slot:activator="{
                                            on,
                                            attrs
                                        }"
                                    >
                                        <v-row
                                            class="
                                                        d-flex
                                                        justify-center
                                                    "
                                        >
                                            <v-switch
                                                outlined
                                                color="success"
                                                v-model="
                                                    usuario.is_lite_readonly
                                                "
                                                :disabled="!isUsuarioLite"
                                                label="Apenas Leitura"
                                                required
                                                data-cy="usuarioReadonly"
                                            ></v-switch>
                                            <v-btn
                                                v-if="!isExtraSmall"
                                                elevation="0"
                                                class="ma-2"
                                                small
                                                fab
                                            >
                                                <v-icon
                                                    color="grey lighten-2"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                    help
                                                </v-icon>
                                            </v-btn>
                                        </v-row>
                                    </template>
                                    <span
                                        >Opção exclusiva para Usuários Lite.
                                        Selecione esta opção para definir se o
                                        Usuário será apenas de consulta. Isso
                                        implica que este usuário não poderá
                                        emitir comandos para títulos, podendo
                                        apenas visualizá-los.
                                    </span>
                                </v-tooltip>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" sm="12" md="4" v-if="superUsuario">
                        <v-card
                            v-if="alterarSenha"
                            min-height="100%"
                            class="grey lighten-4 text-center"
                            style="display: grid; padding: 24px 24px 8px 24px;"
                        >
                            <div
                                style="display: flex;
                                            padding-bottom: 16px;
                                            justify-content: space-between;
                                            align-items: center;
                                        "
                            >
                                <v-card-text
                                    class="text-left"
                                    :class="!isExtraSmall ? 'pa-3' : ''"
                                >
                                    Informe abaixo a nova senha para esse
                                    usuário:
                                </v-card-text>
                                <div class="d-flex justify-center align-center">
                                    <v-tooltip top>
                                        <template
                                            v-slot:activator="{
                                                on,
                                                attrs
                                            }"
                                        >
                                            <v-btn
                                                v-bind="attrs"
                                                v-on="on"
                                                class="mr-3 white--text"
                                                color="primary"
                                                x-small
                                                fab
                                                @click="gerarSenhaAutomatica()"
                                                ><v-icon>mdi-key</v-icon></v-btn
                                            >
                                        </template>
                                        <span>
                                            Gerar senha automaticamente para o
                                            novo usuário
                                        </span>
                                    </v-tooltip>
                                    <v-btn
                                        x-small
                                        fab
                                        :disabled="validarDisableEditarSenha"
                                        @click="alterarSenha = false"
                                        ><v-icon>mdi-close</v-icon></v-btn
                                    >
                                </div>
                            </div>
                            <v-text-field
                                solo
                                :prepend-icon="!isExtraSmall ? 'lock' : ''"
                                autocomplete="new-password"
                                :value="userPassword"
                                v-model="usuario.password"
                                label="Crie uma senha"
                                hint="Sua senha precisa conter no mín. 12 caracteres: maiúsculas, números e caracteres especiais (exemplo: @,!,#)"
                                :append-icon="
                                    mostrarSenha ? 'mdi-eye' : 'mdi-eye-off'
                                "
                                @click:append="mostrarSenha = !mostrarSenha"
                                @input="$refs.passwordConfirmation.validate()"
                                @keyup.native.tab="
                                    $refs.passwordConfirmation.focus()
                                "
                                :type="mostrarSenha ? 'text' : 'password'"
                                :rules="passwordRules"
                                data-cy="usuarioPwd"
                            >
                                <template v-slot:progress>
                                    <v-progress-linear
                                        v-if="custom"
                                        :value="progress"
                                        absolute
                                        height="7"
                                    ></v-progress-linear> </template
                            ></v-text-field>
                            <v-text-field
                                ref="passwordConfirmation"
                                solo
                                :prepend-icon="!isExtraSmall ? 'lock' : ''"
                                name="new-passaword"
                                label="Repita a nova senha"
                                :type="mostrarSenha ? 'text' : 'password'"
                                :append-icon="
                                    mostrarSenha ? 'mdi-eye' : 'mdi-eye-off'
                                "
                                @click:append="mostrarSenha = !mostrarSenha"
                                v-model="usuario.passwordConfirmation"
                                :rules="passwordRulesConfirmation"
                                required
                                data-cy="usuarioPwdConfirm"
                            ></v-text-field>
                        </v-card>
                        <v-card
                            v-else
                            min-height="100%"
                            class="grey lighten-4 text-center"
                        >
                            <v-col>
                                <v-icon
                                    class="ma-3 pa-3"
                                    elevation="4"
                                    size="120"
                                    color="grey lighten-4--text"
                                    >mdi-lock</v-icon
                                >
                            </v-col>
                            <v-col>
                                <v-btn
                                    class="
                                                green
                                                white--text
                                                ma-3
                                                pa-3
                                            "
                                    data-cy="liberarModalSenha"
                                    @click="alterarSenha = true"
                                    ><v-icon>mdi-lock-reset</v-icon
                                    >{{
                                        isExtraSmall
                                            ? 'Alterar Senha'
                                            : 'Alterar Senha do Usuário'
                                    }}</v-btn
                                >
                            </v-col>
                        </v-card>
                    </v-col>
                </v-row>
            </v-form>
        </v-container>
    </v-card>
</template>

<script>
import {
    validarDsLogin,
    validarDsLoginAdmin,
    validarEmail,
    validarNome,
    validarSenha,
    validarStringSemEspaco,
    validarAgrupador,
    listarAgrupadores,
    corrigirDsLoginAdmin
} from '@/helpers/Utilitarios';
import { gerarSenha } from '@/helpers/GeradorSenha';

export default {
    name: 'DetalheUsuario',
    props: {
        usuario: {
            type: Object,
            required: false
        },
        validarForm: {
            type: Boolean,
            required: false
        },
        showActionSalvar: Boolean
    },
    data() {
        return {
            isUserApi: false,
            tpUsuario: ['admin', 'basic', 'lite'],
            isExtraSmall: false,
            isSmall: false,
            alterarSenha: false,
            passwordConfirmation: '',
            custom: true,
            userPassword: '',
            confirmPassword: null,
            superUsuario: false,
            mostrarSenha: false,
            errorMessages: null,
            items: ['Item 1', 'Item 2', 'Item 3', 'Item 4'],
            id_user: '',
            ds_login: '',
            st_ativo: false,
            enabled: false,
            password: '',
            email: '',
            valid: null,
            createPassword: '',
            emailConfirmation: '',
            rules: {
                required: value => !!value || 'Required.',
                password: value => {
                    const pattern =
                        // eslint-disable-next-line no-useless-escape
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
                    return (
                        pattern.test(value) ||
                        'Sua senha precisa conter no mín. 12 caracteres: maiúsculas, números e caracteres especiais (exemplo: @,!,#)'
                    );
                }
            },
            passwordRules: [
                v => !!v || 'Senha é requerida',
                v =>
                    this.validarSenha(v) ||
                    'Sua senha precisa conter no mín. 12 caracteres: maiúsculas, números e caracteres especiais (exemplo: @,!,#)',
                v =>
                    this.validarStringSemEspaco(v) ||
                    'Não é permitido utilizar espaços.'
            ],
            passwordRulesConfirmation: [
                v =>
                    this.validarConfirmacaoSenha(v) ||
                    'Confirmação de Senha requerida',
                v =>
                    !v ||
                    v === this.usuario.password ||
                    'As senhas devem ser iguais',
                v =>
                    this.validarStringSemEspaco(v) ||
                    'Não é permitido utilizar espaços.'
            ],
            loginRulesAdminConfirmation: [
                v => !!v || 'Login é requerido',
                v => this.validarDsLoginAdmin(v, this.cdApresentante) || `Preencha o login em formato válido: usuario@${this.cdApresentante}`,
                v => this.validarStringSemEspaco(v) || 'Não é permitido utilizar espaços.'
            ],
            loginRulesConfirmation: [
                v => !!v || 'Login é requerido',
                v =>
                    this.validarStringSemEspaco(v) ||
                    'Não é permitido utilizar espaços.'
            ],
            emailRulesConfirmation: [
                v => !!v || 'E-mail é requerido',
                v =>
                    this.validarEmail(v) ||
                    'Preencha o e-mail em formato válido: exemplo@exemplo',
                v =>
                    this.validarStringSemEspaco(v) ||
                    'Não é permitido utilizar espaços.'
            ],
            nameRulesConfirmation: [
                v => !!v || 'Nome é requerido',
                v =>
                    this.validarNome(v) ||
                    'Tamanho máximo 45 (não permite números e acentos)'
            ],
            agrupadorRulesConfirmation: [
                v =>
                    this.validarAgrupador(v) ||
                    'Tamanho deve ser 3, sem espaços/caracteres'
            ],
            loadingAgrupadores: false
        };
    },
    methods: {
        corrigirDsLoginAdmin,
        validarDsLogin,
        validarDsLoginAdmin,
        validarEmail,
        validarNome,
        validarSenha,
        validarStringSemEspaco,
        validarAgrupador,
        listarAgrupadores,
        gerarSenha,
        gerarSenhaAutomatica() {
            let novaSenha = this.gerarSenha();

            this.usuario.password = novaSenha;
            this.usuario.passwordConfirmation = novaSenha;

            this.mostrarSenha = true;
            this.$forceUpdate();
        },
        verificarPreenchimentoLoginAdmin() {
            const loginCorrigido = this.corrigirDsLoginAdmin(
                this.usuario.ds_login,
                this.cdApresentante
            );
            this.usuario.ds_login = loginCorrigido;
        },
        validarConfirmacaoSenha(passwordConfirmation) {
            if (
                this.usuario.password &&
                this.usuario.password !== '' &&
                (!passwordConfirmation || passwordConfirmation === '')
            ) {
                return false;
            }
            return true;
        },
        salvar() {
            this.$emit('actionSalvar');
        },
        handleBreakpoints(val) {
            this.isExtraSmall = false;
            this.isSmall = false;
            if (val.xs) {
                this.isExtraSmall = true;
            } else if (val.sm) {
                this.isSmall = true;
            }
        },
        keyupListener(e) {
            if (!e) e = window.event;
            const keyCode = e.code || e.key;

            if (keyCode === 'Escape') {
                this.$emit('fecharModal');
            } else if (keyCode === 'Enter' || keyCode === 'NumpadEnter') {
                this.validarSalvar();
            }
        },
        validarSalvar() {
            if (this.valid) {
                this.usuario.tp_perfil = this.isUserApi ? 'API' : 'PAINEL';
                this.$emit('salvarUsuario');
            } else {
                this.$refs.form.validate();
            }
        },
        async checarAgrupadores() {
            try {
                this.loadingAgrupadores = true;
                await this.listarAgrupadores(this.$store, true);
            } catch (e) {
                console.log(e);
                // TODO chamar snackbar
            } finally {
                this.loadingAgrupadores = false;
            }
        }
    },
    watch: {
        breakpoint: {
            deep: true,
            handler(val) {
                this.handleBreakpoints(val);
            }
        },
        valid(val) {
            this.$emit('setIsInvalid', !val);
        },
        validarForm(val) {
            if (val) {
                this.$refs.form.validate();
                this.$emit('formValidado', false);
            }
        },
        usuario: {
            deep: true,
            handler(val) {
                if (val.tp_usuario !== 'lite') {
                    this.usuario.is_lite_readonly = false;
                }
            }
        },
        isUserApi(val) {
            if (val) {
                this.usuario.tp_perfil = 'A';
            } else {
                this.usuario.tp_perfil = 'M';
            }
        }
    },
    computed: {
        isSuperAdminRecuperi() {
            return this.$store.getters.isSuperAdminRecuperi;
        },
        progress() {
            return Math.min(100, this.usuario.password.length * 12);
        },
        breakpoint() {
            return this.$vuetify.breakpoint;
        },
        isUsuarioLite() {
            return this.usuario.tp_usuario === 'lite';
        },
        agrupadores() {
            return this.$store.getters.listaAgrupadores;
        },
        usuarioAgrupador() {
            return this.$store.getters.agrupadorUsuario;
        },
        validarDisableEditarSenha() {
            if (
                (this.usuario.password && this.usuario.password !== '') ||
                (this.usuario.passwordConfirmation &&
                    this.usuario.passwordConfirmation !== '')
            ) {
                return true;
            }
            return false;
        },
        cdApresentante() {
            return this.$store.getters.cdApresentante;
        }
    },
    beforeDestroy() {
        document.removeEventListener('keyup', this.keyupListener);
    },
    async mounted() {
        document.addEventListener('keyup', this.keyupListener);
        this.handleBreakpoints(this.breakpoint);
        this.isUserApi = this.usuario.tp_perfil === 'API';

        this.usuario.passwordConfirmation = null;

        if (this.$store.getters.getSuperUser == 1) {
            this.superUsuario = true;
        } else {
            this.superUsuario = false;
        }

        if (this.usuarioAgrupador) {
            this.usuario.ds_agrupador = this.usuarioAgrupador;
        }

        await this.checarAgrupadores();
    }
};
</script>

<style scoped>
#margin-dialog-body {
    margin-top: 15px;
    margin-right: 15px;
    margin-bottom: 15px;
    margin-left: 0px;
}
</style>
