export function gerarSenha() {
    const caracteresMinusculos = 'abcdefghijklmnopqrstuvwxyz';
    const caracteresMaiusculos = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const caracteresDigitos = '0123456789';
    const caracteresEspeciais = '@$!#%*&';

    const todosCaracteres =
        caracteresMinusculos +
        caracteresMaiusculos +
        caracteresDigitos +
        caracteresEspeciais;

    const obterCaractereAleatorio = caracteres =>
        caracteres[Math.floor(Math.random() * caracteres.length)];

    const senha =
        obterCaractereAleatorio(caracteresMinusculos) +
        obterCaractereAleatorio(caracteresMaiusculos) +
        obterCaractereAleatorio(caracteresDigitos) +
        obterCaractereAleatorio(caracteresEspeciais) +
        Array.from({ length: 12 }, () =>
            obterCaractereAleatorio(todosCaracteres)
        ).join('');

    return senha
        .split('')
        .sort(() => Math.random() - 0.5)
        .join('');
}
