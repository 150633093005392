<template>
    <v-dialog
        v-model="showDialog"
        max-width="80vw"
        persistent
        scrollable
        color="background"
        transition="dialog-bottom-transition"
    >
        <v-card>
            <v-card-title class="pa-0">
                <v-toolbar flat dark dense color="primary">
                    <v-btn icon dark @click="fecharModal">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title class="pa-0">
                        Histórico do Usuário
                    </v-toolbar-title>
                </v-toolbar>
            </v-card-title>
            <v-divider />
            <v-card-text class="py-5 px-6 text-justify content-style">
                <div class="pb-4">
                    <span class="text-h6 font-weight-medium black--text"
                        >Consulta de histórico de ações do usuário
                        {{ `${usuario.name} (Id: ${usuario.id_user})` }} nos
                        últimos 30 dias.</span
                    >
                </div>
                <v-data-table
                    :headers="headers"
                    :items="historicos"
                    item-key="historicos"
                    class="elevation-1 pa-3"
                    :footer-props="{
                        itemsPerPageOptions: [10, 20, 50, -1],
                        itemsPerPageText: 'Históricos por página:'
                    }"
                    data-cy="tabelaHistoricos"
                >
                    <template slot="item.historico_datahora" slot-scope="props">
                        {{
                            formatarDataHoraMoment(
                                props.item.historico_datahora,
                                'DD/MM/YYYY HH:mm'
                            )
                        }}
                    </template>
                </v-data-table>
            </v-card-text>
            <v-divider />
            <v-card-actions class="justify-end">
                <v-btn
                    class="ml-4"
                    @click="fecharModal"
                    data-cy="fecharConfirmacaoModal"
                >
                    Fechar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { formatarDataHoraMoment } from '@/helpers/Utilitarios';

export default {
    name: 'HistoricoUsuarioModal',
    props: {
        showDialog: { type: Boolean, default: false },
        historicos: {
            type: Array,
            default: () => {
                return [];
            }
        },
        usuario: {
            type: Object,
            default: () => {
                return {};
            }
        }
    },
    data() {
        return {
            headers: [
                {
                    text: 'Log Id',
                    sortable: false,
                    value: 'historico_id'
                },
                {
                    text: 'Data/Hora',
                    sortable: false,
                    value: 'historico_datahora',
                    width: '150px'
                },
                {
                    text: 'URL',
                    sortable: false,
                    value: 'historico_comando'
                },
                {
                    text: 'Payload',
                    sortable: false,
                    value: 'historico_payload'
                }
            ]
        };
    },
    beforeDestroy() {
        document.removeEventListener('keyup', this.keyupListener);
    },
    mounted() {
        document.addEventListener('keyup', this.keyupListener);
    },
    methods: {
        formatarDataHoraMoment,
        fecharModal() {
            this.$emit('fecharModal');
        },
        keyupListener(e) {
            if (!e) e = window.event;
            const keyCode = e.code || e.key;
            if (keyCode === 'Escape') {
                this.fecharModal();
            }
        }
    }
};
</script>

<style>
.content-style {
    font-size: 1rem;
    border-radius: 0;
}
</style>
