export const updatesRelease = [
    ///////////// detalhes do título
    {
        funcionalidade: 'Correção',
        admin: true,
        basic: true,
        lite: false,
        category: 'Detalhes do Título'
    },
    {
        funcionalidade: 'Anuencia',
        admin: true,
        basic: true,
        lite: true,
        category: 'Detalhes do Título'
    },
    {
        funcionalidade: 'Ações para Codevedores',
        admin: true,
        basic: true,
        lite: false,
        category: 'Detalhes do Título'
    },
    {
        funcionalidade: 'Cancelamento',
        admin: true,
        basic: true,
        lite: true,
        category: 'Detalhes do Título'
    },
    {
        funcionalidade: 'Desistência',
        admin: true,
        basic: true,
        lite: true,
        category: 'Detalhes do Título'
    },
    {
        funcionalidade: 'Editar Apontamento',
        admin: true,
        basic: true,
        lite: false,
        category: 'Detalhes do Título'
    },
    {
        funcionalidade: 'Imprimir Detalhes do Título',
        admin: true,
        basic: true,
        lite: true,
        category: 'Detalhes do Título'
    },
    {
        funcionalidade: 'Imprimir Título',
        admin: true,
        basic: true,
        lite: true,
        category: 'Detalhes do Título'
    },
    ///////////// consulta titulos
    {
        funcionalidade: 'Filtros de Revisão',
        admin: true,
        basic: true,
        lite: false,
        category: 'Consulta de Títulos'
    },
    {
        funcionalidade: 'Não Mostra Irregulares',
        admin: true,
        basic: true,
        lite: false,
        category: 'Consulta de Títulos'
    },
    {
        funcionalidade: 'Exporta PDF com os Resultados',
        admin: true,
        basic: true,
        lite: false,
        category: 'Consulta de Títulos'
    },
    {
        funcionalidade: 'Exporta XLS com os Resultados',
        admin: true,
        basic: true,
        lite: true,
        category: 'Consulta de Títulos'
    },
    {
        funcionalidade: 'Exporta Relatório Detalhado',
        admin: true,
        basic: true,
        lite: false,
        category: 'Consulta de Títulos'
    },
    ///////////// alteracoes em massa
    {
        funcionalidade: 'Cancelamento',
        admin: false,
        basic: false,
        lite: false,
        category: 'Alterações em lote'
    },
    {
        funcionalidade: 'Desistência',
        admin: false,
        basic: false,
        lite: false,
        category: 'Alterações em lote'
    },
    {
        funcionalidade: 'Anuência',
        admin: false,
        basic: false,
        lite: false,
        category: 'Alterações em lote'
    },
    ///////////// mensageria
    {
        funcionalidade: 'Enviar E-mail',
        admin: true,
        basic: false,
        lite: false,
        category: 'Mensageria'
    },
    {
        funcionalidade: 'Ajustes',
        admin: true,
        basic: false,
        lite: false,
        category: 'Mensageria'
    },
    {
        funcionalidade: 'Relatórios',
        admin: true,
        basic: false,
        lite: false,
        category: 'Mensageria'
    },
    ///////////// reatorios
    {
        funcionalidade: 'Títulos por Status',
        admin: true,
        basic: false,
        lite: false,
        category: 'Relatórios'
    },
    {
        funcionalidade: 'Tabela de Emolumentos',
        admin: true,
        basic: false,
        lite: false,
        category: 'Relatórios'
    },
    {
        funcionalidade: 'Detalhado',
        admin: true,
        basic: false,
        lite: false,
        category: 'Relatórios'
    },
    {
        funcionalidade: 'Mensageria',
        admin: true,
        basic: false,
        lite: false,
        category: 'Relatórios'
    },
    {
        funcionalidade: 'Repasses e Taxas',
        admin: true,
        basic: false,
        lite: false,
        category: 'Relatórios'
    },
    /////////// geral
    {
        funcionalidade: 'Tela Inicial - Gráficos',
        admin: true,
        basic: true,
        lite: false,
        category: '1. Funcionalidades Gerais'
    },
    {
        funcionalidade: 'Upload de Títulos',
        admin: true,
        basic: true,
        lite: false,
        category: '1. Funcionalidades Gerais'
    },
    {
        funcionalidade: 'Consulta Títulos',
        admin: true,
        basic: true,
        lite: true,
        category: '1. Funcionalidades Gerais'
    },
    {
        funcionalidade: 'Relatórios',
        admin: true,
        basic: false,
        lite: false,
        category: '1. Funcionalidades Gerais'
    },
    {
        funcionalidade: 'Alterações em lote',
        admin: true,
        basic: false,
        lite: false,
        category: '1. Funcionalidades Gerais'
    },
    {
        funcionalidade: 'Ofertas/Renegociacões',
        admin: false,
        basic: false,
        lite: false,
        category: '1. Funcionalidades Gerais'
    },
    {
        funcionalidade: 'Mensageria',
        admin: true,
        basic: false,
        lite: false,
        category: '1. Funcionalidades Gerais'
    },
    {
        funcionalidade: 'Mapa de Títulos',
        admin: true,
        basic: true,
        lite: false,
        category: '1. Funcionalidades Gerais'
    },
    {
        funcionalidade: 'Manual do Usuário',
        admin: true,
        basic: true,
        lite: false,
        category: '1. Funcionalidades Gerais'
    },
    {
        funcionalidade: 'Sobre',
        admin: true,
        basic: true,
        lite: false,
        category: '1. Funcionalidades Gerais'
    },
    {
        funcionalidade: 'Dúvidas Frequentes',
        admin: true,
        basic: true,
        lite: false,
        category: '1. Funcionalidades Gerais'
    },
    {
        funcionalidade: 'Listar Usuários',
        admin: true,
        basic: false,
        lite: false,
        category: '1. Funcionalidades Gerais'
    }
];

export default updatesRelease;
